import React from 'react';
import ContentfulSection from 'components/Contentful/ContentfulSection';
import ContentfulLayout from 'components/Contentful/ContentfulLayout';
import transformTemplateData from 'utils/transformTemplateData';
import Carousel from 'components/Core/Carousel';

const ContentfulCarousel = ({ layouts, ...props }) => {
  const includesFullWidthBackground = layouts?.some(
    (layout) => layout?.template?.fullWidthBackground,
  );

  return (
    <Carousel
      {...props}
      heading={props?.heading2}
      subhead={props?.subhead2}
      includesFullWidthBackground={includesFullWidthBackground}
    >
      {layouts?.map((layout, index) => {
        if (layout.templateName === 'Bender') {
          return <ContentfulLayout {...layout} key={`carousel-${index}`} />;
        }
        const item = transformTemplateData(layout);
        return <ContentfulSection {...item} key={`carousel-${index}`} />;
      })}
    </Carousel>
  );
};
export default ContentfulCarousel;
